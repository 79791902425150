<template>
  <div>
    <div class="form-template-overview-container">
      <div class="md-layout">
        <div class="md-layout-item md-size-100 padding-top-controls md-alignment-bottom-left">
          <div class="filtering-card md-layout">
            <!-- Filter by Tags -->
            <div class="md-layout-item md-size-33 ">
              <!-- <label class="md-form-label">Filter by Tags</label> -->
              <md-field>
                <vue-tags-input
                  class="avalia-tags-input"
                  v-model="tag"
                  :tags.sync="filters.tags"
                  :autocomplete-items="autocompleteItems"
                  :placeholder="tagsInputPlaceholder"
                  :add-only-from-autocomplete="true"
                  @tags-changed="tagsChanged"
                />
              </md-field>
            </div>
          </div>
        </div>
        <div class="md-layout-item md-size-100">
          <md-card v-if="hasFormTemplates" class="form-template-table-card">
            <md-card-content>
              <div v-if="loading">
                <loader />
              </div>
              <div v-else>
                <md-table :value="formTemplates" class="paginated-table table-striped table-hover custom-table">
                  <md-table-row
                    slot="md-table-row"
                    slot-scope="{ item }"
                  >
                    <md-table-cell md-label="Last Version Name" class="table-cell-name">
                      {{ item.versions.reduce((prev, current) => (prev.version > current.version) ? prev : current).name  }}
                    </md-table-cell>
                    <md-table-cell md-label="Key" class="table-cell-key">
                      {{ item.formTemplateKey }}
                    </md-table-cell>

                    <md-table-cell md-label="Tags" class="table-cell-tags custom-table-cell">
                      <div v-if="item.tags.length">
                        <md-chip
                          class="md-info custom-chip"
                          v-for="tag in item.tags"
                          :key="tag.name"
                          md-disabled
                          >{{ tag.name }}</md-chip
                        >
                      </div>
                      <div v-else>
                        <div class="discrete">no tag</div>
                      </div>
                    </md-table-cell>

                    <md-table-cell md-label="Versions" class="table-cell-version">
                      <md-chip
                        @click="formTemplateVersionClicked({formTemplate:item, version}, $event)"
                        class="md-info version-chip"
                        v-for="version in item.versions"
                        :key="version.version"
                        md-disabled
                        >v{{ version.version }}</md-chip>
                    </md-table-cell>
                    <md-table-cell md-label="Actions" class="table-cell-actions">
                      <span @click="formTemplateViewLastVersionClicked(item, $event)">
                        <md-icon class="icon-action-big icon-view">search</md-icon>
                        <md-tooltip md-delay="300">View Form Template Last Version</md-tooltip>
                      </span>

                    </md-table-cell>
                  </md-table-row>
                </md-table>
              </div>
            </md-card-content>
          </md-card>
          <md-card >
            <!-- Pagination footer -->
            <md-card-actions md-alignment="space-between" class="pagination-container">
              <!-- Pagination: Per Page -->
              <div class="md-layout-item md-size-15 md-medium-size-15 nopadding">
                <div class="pagination-card">
                  <md-content class="pagination-card-content">
                    <div class="md-layout pagination-layout md-alignment-center-space-between">
                      <div
                        class="md-layout-item md-size-100 per-page-container md-alignment-center-space-around"
                      >
                        <md-field>
                          <label for="pages">Per page</label>
                          <md-select
                            :value="pagination.perPage"
                            name="pages"
                            @md-selected="nbItemsPerPageChange"
                          >
                            <md-option
                              v-for="item in pagination.perPageOptions"
                              :key="item"
                              :label="item"
                              :value="item"
                              class="noshadow"
                            >
                              {{ item }}
                            </md-option>
                          </md-select>
                        </md-field>
                      </div>
                    </div>
                  </md-content>
                </div>
              </div>
              <div>
                <p class="card-category">
                  Showing {{ total === 0 ? 0 : from + 1 }} to {{ to }} of {{ total }} form templates
                </p>
              </div>
              <pagination
                :value="pagination.currentPage"
                class="pagination-info"
                :per-page="pagination.perPage"
                :total="total"
                @input="(page) => paginationChange(page)"
              />
            </md-card-actions>
          </md-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import api from '@/api/api';
import { debounce } from 'lodash';
import Loader from '@/components/Avalia/Loader/Loader.vue';
import { Pagination } from '@/components/Template';
import VueTagsInput from '@johmun/vue-tags-input';
import api from '@/api/api';
import notify from '@/utils/notificationsUtils';
//import Swal from 'sweetalert2';

/*
const swalWithCustomButtons = Swal.mixin({
  customClass: {
    confirmButton: 'md-button md-success btn-fill',
    cancelButton: 'md-button md-danger btn-fill',
  },
  buttonsStyling: false,
});
*/

export default {
  name: 'form-templates-overview',
  components: {
    Loader,
    Pagination,
    VueTagsInput,
  },
  data() {
    return {
      tag: '',
      tagType: 'form template tag',
      autocompleteItems: [],
      tagsInputPlaceholder: 'Filter by tags...',
    };
  },
  computed: {
    formTemplates() {
      return this.$store.getters['formTemplates/GET_FORM_TEMPLATES'];
    },
    hasFormTemplates() {
      return this.formTemplates.length > 0;
    },
    pagination() {
      return this.$store.getters['formTemplates/GET_PAGINATION'];
    },
    filters() {
      return this.$store.getters['formTemplates/GET_FILTERS'];
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.$store.getters['formTemplates/GET_FORM_TEMPLATES_TOTAL'];
    },
    loading() {
      return this.$store.getters['formTemplates/GET_LOADING'];
    },
  },
  watch: {
    pagination: {
      handler() {
        this.fetchFormTemplates();
      },
      deep: true,
    },
    filters: {
      handler() {
        this.fetchFormTemplates();
      },
      deep: true,
    },
    tag: {
      handler() {
        this.loadTags();
      },
    },
  },
  created() {
    // On created
    this.fetchFormTemplates();
  },
  methods: {
    fetchFormTemplates: debounce(
      function deb() {
        this.$store.commit('formTemplates/SET_LOADING', true);
        const payload = {
          currentPage: this.pagination.currentPage,
          perPage: this.pagination.perPage,
          vm: this,
          tags: this.filters.tags,
        };
        this.$store.dispatch('formTemplates/fetchFormTemplates', payload)
          .then(() => this.$store.commit('formTemplates/SET_LOADING', false));
      },
      500,
    ),
    paginationChange(numPage) {
      this.$store.commit('formTemplates/SET_PAGINATION_CURRENT_PAGE', numPage);
    },
    nbItemsPerPageChange(nbItemsPerPage) {
      this.$store.commit('formTemplates/SET_PAGINATION_PER_PAGE', nbItemsPerPage);
    },
    formTemplateViewLastVersionClicked(formTemplate, event) {
      //console.log('formTemplate clicked ', formTemplate);
      // Identify formTemplate's last version
      const lastVersion = formTemplate.versions.reduce((prev, current) => ((prev.version > current.version) ? prev : current));
      //console.log('lastVersion', lastVersion);
      this.viewFormTemplateVersion(lastVersion, event);
    },
    formTemplateVersionClicked({ formTemplate, version }, event) {
      //console.log('formTemplate clicked formTemplate', formTemplate);
      //console.log('formTemplate clicked version', version);
      this.viewFormTemplateVersion(version, event);
    },
    viewFormTemplateVersion(version, event) {
      this.$track('fe_viewFormTemplateDetails', version);
      const location = {
        name: 'Form Template Version Details',
        params: { formTemplateVersionId: version.id },
      };
      // In current setup the new page opens on a login page so currently not usable
      /*
      if (event.metaKey || event.ctrlKey) {
        const routeData = this.$router.resolve(location);
        window.open(routeData.href, '_blank');
      } else { */
      this.$router.push(location);
      //}
    },
    // Tags methods
    loadTags() {
      if (this.tag.length < 1) return;

      const url = `/tags?name=${this.tag.toLowerCase()}&type=${this.tagType}`;

      clearTimeout(this.debounce);
      // Using a custom debounce function to avoid executing tag fetching request on each character typed
      this.debounce = setTimeout(() => {
        api.assess
          .get(url)
          .then((response) => {
            this.autocompleteItems = response.data.map((t) => ({
              text: t.name,
            }));
          })
          .catch((error) => {
            // console.log(error);
            const errorMessage = `Could not Fetch Form Template Tags : ${error.response.data.message || ''}`;
            notify.notifyError(errorMessage, this);
          });
      }, 500);
    },
    tagsChanged(newTags) {
      this.autocompleteItems = [];
      this.tags = newTags;
    },
    filterTagsChange(tags) {
      this.$store.commit('formTemplates/SET_FILTERS_TAGS', tags);
    },
    resetSearch() {
      this.$store.commit('formTemplates/RESET_FILTERS_AND_PAGINATION');
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-container {
  padding: 0rem 0rem 0.6rem;
}
.pagination-container {
  padding-right: 6rem;
  padding-top: 1rem;
}
.pagination-card-content {
  padding: 0rem 1.25rem 0rem;
}
.form-template-table-card {
  margin-top: 1rem;
}
.md-table-head-container {
  padding-left: 1rem !important;
}
.table-cell-name{
  width: 25%;
  &::v-deep .md-table-cell-container {
    padding-left: 1rem;
  }
}
.table-cell-key{
  width: 35%;
}
.table-cell-tags {
  width: 15%;
}
.table-cell-version{
  width: 20%;
}
.table-cell-actions{
  width: 5%;

  &::v-deep .md-table-cell-container {
    padding-right: 1rem;
  }
}
.custom-chip {
  margin-left: 0px;
  margin-right: 4px;
}
.discrete {
  color: rgba(87, 87, 87, 1);
}

/*
.custom-table {
  &::v-deep th:not(:first-child) {
    padding-left: 1rem !important;
  }
} */

.paginated-table .md-table-head:last-child {
       justify-content: flex-start !important;
 }
.version-chip {
  margin-left: 0px;
  margin-right: 4px;
  cursor: pointer;
  //opacity: 0.6;
  color: $avalia-cyan !important;
  background-color: white !important;
  border-style: solid !important;
  border-width: 2px !important;
  font-size: 14px !important;
  &:hover {
    color: white !important;
    background-color: $avalia-cyan !important;
    opacity: 0.8 !important;
    border-color: $avalia-cyan !important;
  }
}
</style>
